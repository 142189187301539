import React, {useState} from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader} from '../Modal';
import {Col, Form, Row} from 'react-bootstrap';
import Summary from "../Summary";
import moment from 'moment';
import {validateFields} from "../../actions";
import FormBuilder from "../FormBuilder";


function ReturnToOwnerItemModal(props) {

  const initialState = {
    claimed_date: moment(),
    claimed_by_entity_type: 'member',
    pickup_date: moment(),
    item: props.item,
  };

  const [state, setState] = useState(initialState);

  const {
    item,
    claimed_by_entity_id,
    claimed_date,
    claimed_by_entity_type,
    pickup_date,
  } = state;

  const {
    date_format,
    customer_id,

    members,
    guests,
    employees,
    managers,

    modal_return_to_owner_item_is_open,

    closeModal,
    transitionAction,
    createEmployee,
    createGuest,
  } = props;

  const required_fields = ['claimed_date', 'claimed_by_entity_id'];

  const fields_list = ['claimed_date', 'claimed_by_entity_id', 'pickup_date'];

  const handleClose = () => closeModal();

  const handleInputChange = (name, value) => setState((prev) => ({...prev, [name]: value}));

  const handleCustomerTypeChange = (field, value) => {
    setState((prev) => ({...prev, [`${field}_entity_type`]: value, [`${field}_entity_id`]: null}));
  }

  const handleDateChange = (name, date) => handleInputChange(name, date);


  const handleCreateEmployee = (fields, entity_name) => {
    createEmployee(fields, entity_name).then((response => {
      setState({...state, [entity_name]: response})
    }))
  }

  const handleCreateGuest = (fields, entity_name) => {
    createGuest(fields, entity_name).then((response => {
      setState({...state, [entity_name]: response})
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFields(state, required_fields);

    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((message, index) => window.gems.toast_message.init(message, "warning"))
    } else {
      console.log("Form submitted successfully", state);

      transitionAction(item, 'claim_item', {
        claimed_date: claimed_date,
        claimed_by_entity_type: claimed_by_entity_type,
        claimed_by_entity_id: claimed_by_entity_id,
      }).then(response => {
        if (response)
          transitionAction(item, 'return_to_owner', {
            pickup_date: pickup_date,
          })
      })

    }
  };

  const title = 'RETURN TO ITEM OWNER';
  const submitButtonName = 'Save';

  return (<div className="modal_return_to_owner_item_container">
    <Modal isOpen={modal_return_to_owner_item_is_open} scrollable={true} className="ReturnToOwnerItemModal"
           dialogClassName="modal-dialog-centered">
      <ModalHeader>
        <div className="row">
          <div className="col">
            <h4 className="modal-title form_new_edit no-margin">{title}</h4>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Form id="ReturnToOwnerItemForm">
          <div className="row h-100">
            <div className="col pt-2 overflow-auto min-height-body-modal mb-2">
              <Row>
                <Col className='color_orange mb-4'>
                  <div className='text-center icon-item-returned-replaced font_size_50'></div>
                  <div className='text-center'>
                    <label className='bold'>
                      The item has been claimed
                    </label>
                  </div>
                </Col>
              </Row>

              <FormBuilder
                fields_list={fields_list}
                item={state}
                members={members}
                employees={employees}
                guests={guests}
                managers={managers}
                date_format={date_format}

                handleCustomerTypeChange={handleCustomerTypeChange}
                handleInputChange={handleInputChange}
                handleCreateEmployee={handleCreateEmployee}
                handleCreateGuest={handleCreateGuest}
                handleDateChange={handleDateChange}
              />
            </div>
            <div className="col pt-2 h-auto overflow-auto background_grey summary_container min-height-body-modal">
              <Summary customer_id={customer_id}
                       files={item.files}
                       state={item}
                       isRestricted={true}
                       btn_name={submitButtonName || null}
                       notification_communication_data={{}}
                       handleInputChange={handleInputChange}
              />
            </div>
          </div>


        </Form>
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button"
          aria-label="Close"
          onClick={handleClose}
        >
          Close
        </button>

        <button
          className={`btn gems_custom_button gems_orange_button float-end`}
          onClick={(event) => handleSubmit(event)}
        >
          {submitButtonName}
        </button>
      </ModalFooter>
    </Modal>
  </div>);
}

export default ReturnToOwnerItemModal