import React from 'react'
import ListBodyContainer from '../containers/ListBodyContainer'
import ManageItemModalContainer from "../containers/actions_modals/ManageItemModalContainer";
import ClaimItemModalContainer from "../containers/actions_modals/ClaimItemModalContainer";
import ReturnToOwnerItemModalContainer from "../containers/actions_modals/ReturnToOwnerItemModalContainer";
import MarkAsFoundItemModalContainer from "../containers/actions_modals/MarkAsFoundItemModalContainer";
import ListViewOptionalFieldsModalContainer from "../containers/ListViewOptionalFieldsModalContainer";

function Body(props) {

  const {
    modal_configure_fields_is_open,
    modal_manage_item_is_open,
    modal_claim_item_is_open,
    modal_return_to_owner_item_is_open,
    modal_mark_as_found_item_is_open,
  } = props;

  return (
    <div className="row flex-grow-1 bg-white" id="container_view_dashboard_stock_orders"
         style={{overflowX: "auto", overflowY: "hidden"}}>
      {
        <div className="col p-0 h-100">
          <ListBodyContainer/>
        </div>
      }

      {modal_configure_fields_is_open && <ListViewOptionalFieldsModalContainer/>}
      {modal_manage_item_is_open && <ManageItemModalContainer/>}
      {modal_claim_item_is_open && <ClaimItemModalContainer/>}
      {modal_return_to_owner_item_is_open && <ReturnToOwnerItemModalContainer/>}
      {modal_mark_as_found_item_is_open && <MarkAsFoundItemModalContainer/>}

    </div>
  )
}

export default Body
