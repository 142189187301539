import React from 'react'

import Header from '../components/Header'
import BodyContainer from '../containers/BodyContainer'
import ViewFilterContainer from "../containers/ViewFilterContainer";
import Footer from "./Footer";
import Toast from "../../../shared/components/Toast";

function Dashboard(props) {

  const {
    newFoundItem,
    newLostItem,
    toast,
  } = props;

  return (
    <div className="col-12 h-100 d-flex flex-column">
      <Header/>
      <ViewFilterContainer/>
      <BodyContainer/>
      <Footer
        newFoundItem={newFoundItem}
        newLostItem={newLostItem}
      />
    </div>
  )

}


export default Dashboard
