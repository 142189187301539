import React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveContainer, ReferenceLine, Sector, LabelList, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar, Legend, PieChart, Pie } from 'recharts';
import { Spinner } from 'react-bootstrap';
import Select from 'react-select';
import _, { keys } from 'lodash';
import update from 'immutability-helper';

function BarChartComponent(props) {
  let bk_color = props.componentKey == "top_categories" ? "rgb(255, 243, 235)" : props.componentKey == "top_members" ? "rgb(227, 238, 240)" : "rgb(218, 238, 249)"
  let ch_color = props.componentKey == "top_categories" ? "rgb(242, 76, 0)" : (props.componentKey == "top_members" || props.componentKey.includes("analyzer")) ? "rgb(0, 82, 121)" : "rgb(45, 45, 45)"
  const { sort_by, filter_by, vendor, category, custom_field } = props.filters

  const initialState = {
    vendor: props.filters.vendor,
    category: props.filters.category,
    custom_field: props.filters.custom_field
  }

  const [state, setState] = useState(initialState);

  const { } = state

  const sort_options = props.componentKey.includes("analyzer") && props.moduleKey != "demo_club" ? props.sort_options[category] || [] : props.sort_options
  const sort_options_formated = []
  const sort_value = props.componentKey.includes("analyzer") && props.moduleKey != "demo_club" ? custom_field : sort_by

  if (props.moduleKey == "stock_order" && props.componentKey.includes("analyzer") && sort_options.length > 0 && !_.find(sort_options, (so) => so['value'] == "color_group"))
    sort_options_formated.push({ value: "color_group", label: "Color Group" })

  sort_options.map((so) => {
    sort_options_formated.push({value: (so.id || so.value), label: (so.name || so.label)})
  })

  const handleAnalyzerFilters = (name, value) => {
    props.load_component_data({ key: props.moduleKey, customer_id: props.customer_id, subcomponent_key: props.componentKey, subcomponent_filters: { vendor: vendor, category: value, custom_field: props.sort_options[value][0].value } })
  }

  return (
    <div className='col p-20 add-margin-all-10 mb-0 text-center home_class'>
      <h4 className='text-start header'>{props.title}</h4>
      {
        sort_options.length > 0 &&
        <div className='row m-0 body_blue_component' style={{ backgroundColor: bk_color, height: props.componentKey.includes("analyzer") ? "auto" : "100px" }}>
          {props.componentKey == "top_vendors" && (props.moduleKey == "special_order" || props.moduleKey == "stock_order") && props.chart_data.length > 0 &&
            <div className='col-12 d-flex p-2 pb-0' id="topVCMSelect">
              <span className='add-line-height-35 order-1 color_black'><b style={{ paddingRight: "13px" }}>Filter</b></span>
              <Select className="topVCMSelect order-2 flex-grow-1 ps-3"
                menuPortalTarget={document.getElementById('topVCMSelect')}
                menuPosition={'fixed'}
                name={props.componentKey}
                defaultValue={props.sort_options_for_filter.filter(function (option) {
                  return option.value === filter_by;
                })}
                options={props.sort_options_for_filter}
                isOptionDisabled={(option) => (option.value == "all" || _.find(option.value, (so) => (props.used_category_ids || []).includes(so))) ? false : true}
                onChange={(e) => { props.load_component_data({ key: props.moduleKey, customer_id: props.customer_id, subcomponent_key: props.componentKey, subcomponent_filters: { sort_by: sort_by, filter_by: e.value } }) }} />
            </div>
          }

          {props.componentKey == "top_categories" && (props.moduleKey == "special_order" || props.moduleKey == "stock_order") && <>
            <div className='col-12 d-flex p-2 pb-0' id="topVCMSelect">
              <span className='add-line-height-35 order-1 color_black'><b style={{ paddingRight: "1px" }}>Vendor</b></span>

              <Select className="topVCMSelect order-2 flex-grow-1 ps-3"
                menuPortalTarget={document.getElementById('topVCMSelect')}
                menuPosition={'fixed'}
                name={props.componentKey}
                defaultValue={props.vendors_options.filter(function (option) {
                  return option.value === vendor;
                })}
                options={props.vendors_options}
                onChange={(e) => { props.load_component_data({ key: props.moduleKey, customer_id: props.customer_id, subcomponent_key: props.componentKey, subcomponent_filters: { vendor: e.value } }) }} />
            </div>
          </>}

          {props.componentKey == "category_analyzer" && (props.moduleKey == "stock_order" || props.moduleKey == "special_order") && <>
            <div className='col-12 d-flex p-2 pb-0' id="topVCMSelect">
              <span className='add-line-height-35 order-1 color_black'><b style={{ paddingRight: "13px" }}>Vendor</b></span>
              <div className='order-2 flex-grow-1' style={{ paddingLeft: "42px" }} >
                <Select className="topVCMSelect"
                  menuPortalTarget={document.getElementById('topVCMSelect')}
                  menuPosition={'fixed'}
                  name={props.componentKey}
                  defaultValue={props.vendors_options.filter(function (option) {
                    return option.value === vendor;
                  })}
                  options={props.vendors_options}
                  onChange={(e) => { props.load_component_data({ key: props.moduleKey, customer_id: props.customer_id, subcomponent_key: props.componentKey, subcomponent_filters: { vendor: e.value, category: category, custom_field: custom_field } }) }} /></div>
            </div>
            <div className='col-12 d-flex p-2 pb-0' id="topVCMSelect">
              <span className='add-line-height-35 order-1 color_black'><b style={{ paddingRight: "13px" }}>Category</b></span>
              <div className='order-2 flex-grow-1' style={{ paddingLeft: "29px" }}>
                <Select className="topVCMSelect"
                  menuPortalTarget={document.getElementById('topVCMSelect')}
                  menuPosition={'fixed'}
                  name={props.componentKey}
                  defaultValue={props.categories_options.filter(function (option) {
                    return option.value === category;
                  })}
                  options={props.categories_options}
                  isOptionDisabled={(option) => props.sort_options[option.value].length == 0}
                  onChange={(e) => { handleAnalyzerFilters("category", e.value) }} /></div>
            </div>
          </>
          }
          <div className='col-12 d-flex p-2' id="topVCMSelect">
            <span className='add-line-height-35 order-1 color_black'><b>{props.sortTitle}</b></span>
            <Select className="topVCMSelect order-2 flex-grow-1 ps-3"
              menuPortalTarget={document.getElementById('topVCMSelect')}
              menuPosition={'fixed'}
              name={props.componentKey}
              value={sort_options_formated.filter(function (option) {
                return option.value === sort_value;
              })}
              options={sort_options_formated}
              onChange={(e) => { props.load_component_data({ key: props.moduleKey, customer_id: props.customer_id, subcomponent_key: props.componentKey, subcomponent_filters: { sort_by: e.value, filter_by: filter_by, vendor: vendor, category: category, custom_field: e.value } }) }} />
          </div>
        </div>
      }
      {props.isLoading
        ?
        <div className='spinner_class'>
          <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner" />
        </div>
        :
        <ResponsiveContainer height={450}>
          <BarChart className="col"
            data={props.chart_data}
            layout="vertical"
            margin={{
              top: 20,
              right: 60,
              left: 90,
              bottom: 0,
            }}
          >
            <XAxis type="number" padding={{ left: 0 }} tick={{ stroke: '#30303f', strokeWidth: 1 }} axisLine={false} />
            <YAxis dataKey="name" type="category" tick={{ stroke: '#30303f', strokeWidth: 1, width: 150 }} axisLine={false} />
            <CartesianGrid strokeDasharray="1 0 1" horizontal={false} stroke="#005279" />
            {/* <Tooltip />
          <Legend /> */}
            <Bar barSize={7} dataKey="qty" fill={ch_color}>
              <LabelList dataKey="label" fill='#30303f' fontSize='13' position='right' fontWeight='bold' />
            </Bar>
          </BarChart>
        </ResponsiveContainer>}
    </div>
  )
}
export default BarChartComponent
