import React from 'react';
import { Form, Col, Table, Tab, Tabs, Spinner, Tooltip, OverlayTrigger} from 'react-bootstrap';
import _, { includes } from 'lodash';
import Select from 'react-select';
import { SketchPicker } from 'react-color'; //import the desired picker
import { useState, useEffect } from 'react';
import update from 'immutability-helper';
import TextareaAutosize from 'react-textarea-autosize';
import LogosModalContainer from '../containers/LogosModalContainer'
import CategoryDropdown from './CategoryDropdown';

//stock_order_categories: [StockOrderCategory.stock_order_items[StockOrderItem{ :stock_order_quantities, :stock_order_metadata }]])]
//categories_list: { [Category] } (nested)
//categories_by_id: { [id] : Category(id, name, parent_id) } 
//categories_attributes : { [category_id]: { [metadatum_id]: metadatum_name }}
//sizing_table: { [id, name, values] }
function DetailedEntry(props) {
  const { stock_order_categories, categories_attributes, categories_by_id, sizing_table, modal_logos_open} = props;

  const [changeCategoryMode, setChangeCategoryMode] = useState([]);
  const [selectedItems, setSelectedItems] = useState({})

  const handleCheckboxInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    })
  }

  const sizing_is_present = _.find(stock_order_categories, (soc) => soc.stock_order_sizing_table_id)

  const presetColors = ['#FFFFFF', '#000080', '#3066BE', '#A2D5FA', '#CE0000', '#ffcbcb', '#8B0000', '#BDB76C', '#F78764', '#FE6F1D', '#FFE600', '#155C29', '#62C370', '#C2F9BB', '#40E0D0', '#FD97AF', '#DFA4EF', '#8B3BA0', '#4B065E', '#CC7722', '#83781B', '#FFFFF0', '#C2B280', '#ECD8AE', '#5F2C0F', '#A7A8AA', '#000000']

  let max_qty_values = 0
  stock_order_categories.map((soc) => {
    if(sizing_table[soc.stock_order_sizing_table_id])
      max_qty_values = (max_qty_values < sizing_table[soc.stock_order_sizing_table_id].values.length) ? sizing_table[soc.stock_order_sizing_table_id].values.length : max_qty_values
  })

  const getAllQuantities = (soc) => {
    let allSizesHash = sizing_table[soc.stock_order_sizing_table_id].values.map(function(sz) { return {size: sz, new_size: false} });
    
    soc.stock_order_items.map((item) => {
      item.original_stock_order_quantities.map((size_qty_pair) => {
        if (size_qty_pair.size && !(allSizesHash.some(pair => pair.size === size_qty_pair.size))){
          allSizesHash.push({size: size_qty_pair.size, new_size: true})
        }
      })
    })

    return allSizesHash
  }

  const handleChangeCategoryMode = (soc) => {
    // Switch between MOVE ITEMS and View mode of stock order categories
    if (changeCategoryMode.includes(soc.category_id)){
      setChangeCategoryMode(changeCategoryMode => changeCategoryMode.filter(category_id => category_id !== soc.category_id));

      const updatedSelectedItemsHash = {
        ...selectedItems
      }
      delete updatedSelectedItemsHash[soc.category_id]

      setSelectedItems(updatedSelectedItemsHash)
    }
    else{
      setChangeCategoryMode([...changeCategoryMode, soc.category_id])

      const categoryItemsIds =  soc.stock_order_items.map(soi => soi.id);

      const updatedSelectedItemsHash = {
        ...selectedItems,
        [soc.category_id]: {
          items: categoryItemsIds,
          newCategoryId: null
        }
      }
      setSelectedItems(updatedSelectedItemsHash)
    }
  }

  const handleSelectAllItems = (soc, e) => {
    if (e.target.checked){
      const categoryItemsIds =  soc.stock_order_items.map(soi => soi.id);

      const updatedSelectedItemsHash = {
        ...selectedItems,
        [soc.category_id]: {
          items: categoryItemsIds,
          newCategoryId: selectedItems[soc.category_id].newCategoryId
        }
      }
      setSelectedItems(updatedSelectedItemsHash)
    }
    else{
      const updatedSelectedItemsHash = {
        ...selectedItems,
        [soc.category_id]: {
          items: [],
          newCategoryId: selectedItems[soc.category_id].newCategoryId
        }
      }

      setSelectedItems(updatedSelectedItemsHash)
    }
  }

  const handleSelectItem = (soc, soi, e) => {
    if (e.target.checked){
      const updatedSelectedItemsHash = {
        ...selectedItems
      }
      updatedSelectedItemsHash[soc.category_id].items.push(soi.id)

      setSelectedItems(updatedSelectedItemsHash)

      // Check SELECT ALL ITEMS checkbox if all items are individually checked inside current category
      if (updatedSelectedItemsHash[soc.category_id].items.length === soc.stock_order_items.length){
        const selectAllCategoryItemsCheck = document.getElementById(`select_all_items_${soc.category_id}`);
        selectAllCategoryItemsCheck.checked = true;
      }
    }
    else{
      const updatedSelectedItemsHash = {
        ...selectedItems
      }
      updatedSelectedItemsHash[soc.category_id].items = updatedSelectedItemsHash[soc.category_id].items.filter(soi_id => soi_id !== soi.id);

      setSelectedItems(updatedSelectedItemsHash)

      // Uncheck SELECT ALL ITEMS checkbox from current category
      const selectAllCategoryItemsCheck = document.getElementById(`select_all_items_${soc.category_id}`);
      selectAllCategoryItemsCheck.checked = false;
    }
  }

  const handleChangeStockCategory = (newCategoryId, currentCategory) => {    
    const updatedSelectedItemsHash = {
      ...selectedItems,
      [currentCategory.category_id]: {
        items: [...selectedItems[currentCategory.category_id].items],
        newCategoryId: newCategoryId
      }
    }

    setSelectedItems(updatedSelectedItemsHash)
  }

  const handleSaveCategoryChange = (currentCategory) => {
    const newCategoryId = selectedItems[currentCategory.category_id].newCategoryId
    const newCategoryIndex = _.findIndex(props.stock_order_categories, (c) => c.category_id == newCategoryId && !c.deleted)

    // Now remove moved items from the current category (from which they were move from)
    const currentCategoryIndex = _.findIndex(props.stock_order_categories, (c) => c.category_id == currentCategory.category_id && !c.deleted)
    const currentCategoryRemainingItems = currentCategory.stock_order_items.filter(item => !selectedItems[currentCategory.category_id].items.includes(item.id))

    let movedItems = currentCategory.stock_order_items.filter(item => selectedItems[currentCategory.category_id].items.includes(item.id))
    let currentLineNumber = newCategoryIndex !== -1 ? parseInt((_.maxBy(props.stock_order_categories[newCategoryIndex].stock_order_items, (i) => (i.line)) || {line: 0}).line) : 0

    movedItems.map((item) => {
      currentLineNumber = currentLineNumber + 1
      item.description = getFullItemDescription(currentCategory, item)
      item.line = currentLineNumber
    })

    // Move Items to Newly Selected Category
    let newCategoryItems = [...movedItems]

    let updatedStockOrderCategories = [...props.stock_order_categories]
    
    if (newCategoryIndex !== -1){
      // CASE 1: Selected category already exists inside the stock order, so we prepend existing category's items to moved items
      newCategoryItems = [...props.stock_order_categories[newCategoryIndex].stock_order_items, ...movedItems]
    }
    else{
      // CASE 2: Moving items to a new category (doesn't already exist inside this stock order) so we create the category
      let cat_name = props.categories_by_id[newCategoryId].full_name
      let new_category = {category_id: newCategoryId, stock_order_items: [], category_name: cat_name, qty: 0, total_cost: 0, attributes: props.categories_attributes[newCategoryId].attributes, stock_order_sizing_table_id: props.categories_by_id[newCategoryId].stock_order_sizing_table_id == "0" ? null : props.categories_by_id[newCategoryId].stock_order_sizing_table_id, total_qty_by_sizes: {}}  
      
      updatedStockOrderCategories.push(new_category)
    }

    updatedStockOrderCategories.at(newCategoryIndex).stock_order_items = newCategoryItems
    updatedStockOrderCategories[currentCategoryIndex].stock_order_items = currentCategoryRemainingItems

    if (currentCategoryRemainingItems.length === 0){
      // All items from current category have been moved => delete category
      updatedStockOrderCategories[currentCategoryIndex].deleted = true
    }

    handleChangeCategoryMode(currentCategory)
    if (props.stock_order_ai_upload){
      updatedStockOrderCategories = handleNewSizes(currentCategoryIndex, updatedStockOrderCategories)
      updatedStockOrderCategories = handleNewSizes(newCategoryIndex, updatedStockOrderCategories)
    }

    // Update stock order state
    props.setState(update(props.state, {
      stock_order_categories: {$set: updatedStockOrderCategories}
    }))
  }

  const getFullItemDescription = (soc, soi) => {
    let itemSpecificAttributes = soi.attributes.map(attribute => ({
      id: attribute.metadatum_id,
      name: attribute.name || "",
      value: attribute.values
    }));

    itemSpecificAttributes = itemSpecificAttributes.filter(item_attribute => 
      !(categories_attributes[selectedItems[soc.category_id].newCategoryId].some(cat_attribute => cat_attribute.id === item_attribute.id))
    );

    let fullDescription = soi.description

    itemSpecificAttributes.map((attribute) => {
      if (!fullDescription.includes(`${attribute.name}: ${attribute.value}`) && attribute.name)
        fullDescription = fullDescription + `\n${attribute.name}: ${attribute.value}`
    })

    return fullDescription
  }

  const handleNewSizes = (categoryIndex, updatedStockOrderCategories, event = null) => {
    if (event){
      // CASE 1: Toggle default/custom sizes for category sizing line
      let updatedStockOrderCategories = [...props.stock_order_categories]
      updatedStockOrderCategories.at(categoryIndex).new_sizes_action = event.target.checked ? "default" : "custom"
  
      props.setState(update(props.state, {
        stock_order_categories: {$set: updatedStockOrderCategories}
      }))
      
      return
    }

    // CASE 2: Items have been moved so we recalculate logic for default/custom sizes button
    const sizingTableSizes = updatedStockOrderCategories.at(categoryIndex).stock_order_sizing_table_id ? props.sizing_table[updatedStockOrderCategories.at(categoryIndex).stock_order_sizing_table_id].values : null

    if (sizingTableSizes){
      let allCategoryUniqueSizes = updatedStockOrderCategories.at(categoryIndex).stock_order_items.flatMap(items => 
        items.original_stock_order_quantities.map(qty_by_size => qty_by_size.size)
      );
      allCategoryUniqueSizes = allCategoryUniqueSizes.filter((size, index, arr) => arr.indexOf(size) === index);

      if (props.stock_order_ai_upload && _.difference(allCategoryUniqueSizes, sizingTableSizes).length > 0){
        // Items have sizes that don't exist inside the current sizing line
        updatedStockOrderCategories.at(categoryIndex).new_sizes_action = updatedStockOrderCategories.at(categoryIndex).new_sizes_action || "default"
      }
      else {
        updatedStockOrderCategories.at(categoryIndex).new_sizes_action = null
      }
    }
    else {
      updatedStockOrderCategories.at(categoryIndex).new_sizes_action = null
    }

    return updatedStockOrderCategories
  }
  
  return (<>
      {modal_logos_open && <LogosModalContainer handleLogoChange={props.handleLogoChange} current_logos={props.logos}/>}
      <Table bordered style={{minWidth: sizing_is_present ? "1500px" : "500px"}} className="detailed_entry_table">
        <thead className="backgroundColorGemsBlackGrey">
          <tr>
            {/* <th></th> */}
            <th className='width_55px'>Line #</th>
            <th rowSpan="1" style={{ minWidth: "125px", width: "125px" }}>
              SKU / Style #
            </th>
            <th style={{ minWidth: "200px", width: "30%" }} rowSpan="1" >
              Description
            </th>
            <th rowSpan="1">
              Attributes
            </th>
            <th rowSpan="1" style={{zIndex: 1}}>
              Color Group
            </th>
            <th rowSpan="1" style={{zIndex: 1}}>
              Quantities
            </th>
            <th rowSpan="1" >
              Logo
            </th>
            <th rowSpan="1" >
              Logo Cost
            </th>
            <th rowSpan="1" >
              Cost Per Item
            </th>
            <th rowSpan="1" >
              Total Quantity
            </th>
            <th rowSpan="1" >
              Total Cost
            </th>
            <th rowSpan="1" >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
        { 
          stock_order_categories.map((soc, index_soc) => (
            !soc["deleted"] && <>
              <tr style={{backgroundColor: "rgba(0, 0, 0, 0.06)"}}>
                <td rowSpan="1" colSpan="1">
                  <div className="cursor_pointer" onClick={() => handleChangeCategoryMode(soc)}>
                    <b className="color_orange"> {!changeCategoryMode.includes(soc.category_id) ? "Move Items" : "Cancel"} </b>
                  </div>
                </td>
                <td rowSpan="1" colSpan={'4'}>
                  {!changeCategoryMode.includes(soc.category_id) && <b>{ soc.category_name }</b>}
                  {changeCategoryMode.includes(soc.category_id) && <div className="row">
                    <div className="col-10"><CategoryDropdown categories={props.categories_list} can_place_stock_orders={props.can_place_stock_orders} view_only_stock_orders={props.view_only_stock_orders} handleNewStockCategory={handleChangeStockCategory} currentCategory={soc} newCategoryId={selectedItems[soc.category_id].newCategoryId} allCategoriesById={categories_by_id}/></div>
                    {(selectedItems[soc.category_id].items.length === 0 || !selectedItems[soc.category_id].newCategoryId || selectedItems[soc.category_id].newCategoryId === soc.category_id) && <OverlayTrigger
                      key="confirm_changes"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-confirm_changes`}>
                          {selectedItems[soc.category_id].newCategoryId === soc.category_id ? "Please select a different category!" : "Please select a category and the items you wish to move!"} 
                        </Tooltip>
                      }
                    >
                      <button
                        disabled={true}
                        style={{width: "10%"}}
                        className="gems_orange_button gems_custom_button float-end ms-3 isDisabled"
                      >
                        Move
                      </button>
                    </OverlayTrigger>}
                    {(selectedItems[soc.category_id].items.length !== 0 && selectedItems[soc.category_id].newCategoryId && selectedItems[soc.category_id].newCategoryId !== soc.category_id) && <button
                        className="gems_orange_button gems_custom_button float-end ms-3 cursor_pointer"
                        style={{width: "10%"}}
                        onClick={() => handleSaveCategoryChange(soc)}
                      >
                        Move
                    </button>}
                  </div>}
                </td>
                <td rowSpan="1" colSpan="1">
                  {!changeCategoryMode.includes(soc.category_id) && <div className="row">
                    <Select id = "stock_order_sizing_table_id" 
                                  menuPortalTarget={document.getElementById('StockOrderForm')}
                                  menuPosition={'fixed'} 
                                  name = 'stock_order_sizing_table_id'
                                  className="sost_width"
                                  isDisabled={props.view_only_stock_orders}
                                  value={sizing_table_options(sizing_table, soc).filter(function(option) {
                                    return option.value === (soc.stock_order_sizing_table_id || "");
                                  })}
                                  options={sizing_table_options(sizing_table, soc)} 
                                  onChange={(e) => {props.handleSizingSelectChange(e.value, soc.category_id)}}/>
                      {props.stock_order_ai_upload && soc.new_sizes_action &&
                        <div className={`d-flex flex-row`} style={{width: "fit-content"}}>
                          <Form.Check 
                            value = {false}
                            checked={soc.new_sizes_action === "default"}
                            type='checkbox'
                            className="flex_center add-line-height-35 ps-3 w-auto"
                            onChange={ (e) => handleNewSizes(index_soc, null, e) } 
                          />
                          <p className="ps-2 pt-2">
                            Add new size to default sizing line
                          </p>
                          <OverlayTrigger
                            key={`new_sizes_info_${index_soc}`}
                            placement="top"
                            overlay={
                              <Tooltip id={`new_sizes_info_${index_soc}`}>
                                {"Ticking this checkbox will permanently add the new sizes highlighted in red to the selected sizing line. \
                                Leaving it unchecked will create a custom sizing line specific to this stock order category, which will not be displayed anywhere else. \
                                Only sizes with a quantity other than 0 will get added."}
                              </Tooltip>
                              }>
                            <i className="ms-2 pt-2 gga-info-circle tooltip_info" />
                          </OverlayTrigger>

                        </div>
                      }
                    </div>}
                </td>
                <td rowSpan="1" colSpan="3"></td>
                <td rowSpan="1" className='text-center'>{soc.qty}</td>
                <td rowSpan="1" className='text-center'>{parseFloat(soc.total_cost).toFixed(2)}</td>
                <td rowSpan="1" className='text-center'>
                {!changeCategoryMode.includes(soc.category_id) && <div className="dropdown">
                  <button className="btn dropdown-toggle lh-1 fs-5" aria-expanded = "false" data-bs-toggle = "dropdown" type = "button" id = {`dropdownContainerItemMenu_${soc.category_id}`} />
                  <ul className="dropdown-menu" aria-labelledby = {`dropdownContainerItemMenu_${soc.category_id}`} >
                    {/* <li className="dropdown-item">
                      <a className="ms-3 link" onClick={props.handleRemove} >Select All</a>
                    </li> */}
                    <li className="dropdown-item">
                      <a className="ms-3 link color_red" onClick={() => props.handleRemove(soc.category_id, null)}>Remove All</a>
                    </li>
                  </ul>
                </div>}
                </td>
              </tr>
              <tr style={{backgroundColor: "rgba(0, 0, 0, 0.03)"}}>
                {/* <td></td> */}
                {!changeCategoryMode.includes(soc.category_id) && <td/>}
                {changeCategoryMode.includes(soc.category_id) && <td className='width_50px'>
                  <Form.Check 
                  id = {`select_all_items_${soc.category_id}`}
                  value = {false}
                  defaultChecked = {true}
                  type='checkbox'
                  className="flex_center add-line-height-35 p-0 m-0"
                  onChange={ (e) => handleSelectAllItems(soc, e) } 
                  />
                </td>}
                <td style={{textAlign: "center"}}>{changeCategoryMode.includes(soc.category_id) &&<b>Select All</b>}</td>
                <td></td>
                <td style={{minWidth: categories_attributes[soc.category_id] ? "" : ""}}>
                  <div className='d-flex'>
                  {categories_attributes[soc.category_id]
                      ?
                      (categories_attributes[soc.category_id]).map((attr) => (
                      <div className="mx-2 text-center" style={{ minWidth: "100px", overflow: "hidden", textOverflow: "ellipsis"}}>
                        {attr.name}
                      </div>
                      ))
                      :
                      <>-</>
                      }
                  </div>
                </td>
                <td></td>
                <td className='d-flex' style={{ fontWeight: "bold" }}> 
                  {
                  sizing_table[soc.stock_order_sizing_table_id] ?
                  getAllQuantities(soc).map((br) => (
                    <div className={`width_50px pe-1 text-center ${br.new_size ? 'color_red' : ''}`}>{br.size}</div>
                  )) 
                  :
                  "Quantity"
                  }
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <>
              {
                soc.stock_order_items.map((soi, index_soi) => (
                  !soi["deleted"] &&
                  <tr key={soi.id} >
                    {/* <td className='width_50px'>
                      <Form.Check 
                      value = {false}
                      defaultChecked = {false}
                      type='checkbox'
                      className="flex_center add-line-height-35 p-0"
                      onChange={ handleCheckboxInputChange } 
                      />
                    </td> */}
                    {!changeCategoryMode.includes(soc.category_id) && <td className={ soi.cancellation_date ? "text-decoration-line-through width_50px" : "width_50px"}>
                      { soi.cancellation_date
                      ?
                      <div>
                      { soi.line }
                      </div>
                      :
                      <Form.Control type="number" 
                            name='line'
                            disabled={props.view_only_stock_orders}
                            defaultValue={ soi.line}
                            onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value )}
                            onBlur={(e) => props.sortItems(soc.category_id)}
                            />
                      }
                    </td>}
                    {changeCategoryMode.includes(soc.category_id) && <td>
                      <Form.Check 
                        value = {false}
                        checked={selectedItems[soc.category_id] && selectedItems[soc.category_id].items.includes(soi.id)}
                        type='checkbox'
                        className="flex_center add-line-height-35 p-0 m-0"
                        onChange={ (e) => handleSelectItem(soc, soi, e) } 
                      />
                    </td>}
                    <td className={ soi.cancellation_date ? "text-decoration-line-through width_105px" : "width_105px"}>
                      { soi.cancellation_date
                      ?
                      <div>
                      { soi.sku }
                      </div>
                      :
                      <Form.Control type="text" 
                            name='sku'
                            disabled={props.view_only_stock_orders}
                            value={ soi.sku }
                            onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value )}
                            />
                      }
                    </td>
                    <td className={soi.cancellation_date ? "text-decoration-line-through width_250px" : "width_250px"}>
                        {soi.cancellation_date
                        ?
                        <div>
                          { soi.description }
                        </div>
                        :
                        <TextareaAutosize
                          name='description'
                          disabled={props.view_only_stock_orders}
                          className="w-100 autores"
                          style={{ minRows: 1 }}
                          value={ soi.description }
                          onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value )} />
                        }
                    </td>
                    <td>
                      <div className='d-flex'>
                        { categories_attributes[soc.category_id]
                        ?
                          (categories_attributes[soc.category_id]).map((attr) => (
                          <div style={{ width: "100px" }} className="mx-2">
                          {soi.cancellation_date
                          ?
                          <div className="text-decoration-line-through text-center">
                            {(_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"]}
                          </div>
                          :
                          (attr.name && (attr.name.toLowerCase().replace(" ", "") == "color"))
                          ?
                            <TextareaAutosize
                              name={`${attr.id}`}
                              className="w-100 autores"
                              disabled={props.view_only_stock_orders}
                              style={{ minRows: 1 }}
                              defaultValue={(_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"]}
                              onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value, true )} />
                          :
                            attr.object_type == "dropdown"
                            ?
                              <Select id ={`${attr.id}`}
                                  menuPortalTarget={document.getElementById('StockOrderForm')}
                                  menuPosition={'fixed'} 
                                  name = {`${attr.id}`}
                                  isDisabled={props.view_only_stock_orders}
                                  value={attribute_options(attr.values, (_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"]).filter(function(option) {
                                    return option.value === (_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"];
                                  })}
                                  options={attribute_options(attr.values, (_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"])} 
                                  onChange={(e) => {props.handleInputChange(soc.category_id, soi.id, attr.id, e.value, true )}}/>
                            :
                              <Form.Control type="text" 
                                defaultValue={(_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"]}
                                name={`${attr.id}`}
                                disabled={props.view_only_stock_orders}
                                onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value, true )} 
                                />
                          }
                          </div>
                          ))
                        :
                          <>-</>
                        }
                      </div>
                      </td>
                    <td className={soi.cancellation_date ? "text-decoration-line-through width_50px" : "width_50px"}>
                      {soi.cancellation_date || props.view_only_stock_orders
                      ?
                      <div className="dropdown stock_order_item_color_group_class flex_center">
                        <div className="calendar_color_class add_padding_all_14" style={{backgroundColor: soi.color_group || '#9B9B9B'}} />
                      </div>
                      :
                      <div className="dropdown stock_order_item_color_group_class flex_center">
                        <div className="calendar_color_class add_padding_all_14" style={{backgroundColor: soi.color_group || '#9B9B9B'}} aria-expanded = "false" data-bs-toggle = "dropdown" id = {`dropdownContainerItemMenu_${soc.category_id}`} />
                        <ul className="dropdown-menu" aria-labelledby = {`dropdownContainerItemMenu_${soc.category_id}`} >
                          <li className="dropdown-item">
                            <SketchPicker
                            color={soi.color_group || '#9B9B9B'}
                            disableAlpha={true}
                            width={170}
                            presetColors={presetColors}
                            onChange={(e) => props.handleColorGroupChange(soc.category_id, soi.id, e.hex)}
                          />
                          </li>
                        </ul>
                      </div>
                      }
                    </td>
                    <td className={ soi.cancellation_date ? "text-decoration-line-through" : ""}>
                      <div className='d-flex'>
                      { soi.cancellation_date
                        ?
                          sizing_table[soc.stock_order_sizing_table_id] ?
                            sizing_table[soc.stock_order_sizing_table_id].values.map((br) => (
                              <div className='width_50px pe-1 text-center'>
                                {(_.find(soi.stock_order_quantities, (soq) => soq.size == br )) && (_.find(soi.stock_order_quantities, (soq) => soq.size == br )).qty}  
                              </div>
                            ))
                          : 
                          <div className='width_50px pe-1 text-center'>
                            {soi.qty}
                          </div>  
                        :
                          sizing_table[soc.stock_order_sizing_table_id] ?
                            getAllQuantities(soc).map((br) => (
                              <div className='width_50px pe-1'>
                                <Form.Control type="number" 
                                    name='qty'
                                    disabled={props.view_only_stock_orders}
                                    className={`text-center px-1`}
                                    placeholder='0'
                                    value={(_.find(soi.stock_order_quantities, (soq) => soq.size == br.size )) && (_.find(soi.stock_order_quantities, (soq) => soq.size == br.size )).qty || "" }
                                    onChange={ (e) => props.handleQuantity(soc.category_id, soi.id, br.size, e.target.value )} 
                                    />
                              </div>
                            ))
                          : 
                            <Form.Control type="number" 
                            name='qty'
                            disabled={props.view_only_stock_orders}
                            className="width_50px px-1 text-center"
                            defaultValue={soi.qty}
                            placeholder='0'
                            onChange={(e) => props.handleQuantity(soc.category_id, soi.id, null, e.target.value )} 
                            />

                        }
                      </div>
                    </td>
                    <td className='width_50px text-center'>
                      <>
                      {_.find(props.logos, (l) =>  (l.stock_order_item_id == soi.id) && (l.stock_order_category_id == soc.category_id)) &&
                      <div className="logo_container width_50px mb-1" style={{backgroundImage: `url(${((_.find(props.logos, (l) =>  (l.stock_order_item_id == soi.id) && (l.stock_order_category_id == soc.category_id)) || {url: null})["url"])})`, dataSrc: (_.find(props.logos, (l) =>  (l.stock_order_item_id == soi.id) && (l.stock_order_category_id == soc.category_id)) || {url: null})["url"], height: (_.find(props.logos, (l) =>  (l.stock_order_item_id == soi.id) && (l.stock_order_category_id == soc.category_id)) || {url: null})["url"] ? "60px" : "auto"}}></div>
                      }
                      {!soi.cancellation_date && !props.view_only_stock_orders && <a className="fa fa-pencil" onClick={(e) => props.openLogosModal(soi, soc.category_id)}></a>}
                      {!soi.cancellation_date && !props.view_only_stock_orders && soi.logo_id && <a className="fa fa-remove color_red ms-2" onClick={(e) => props.handleLogoChange(soc.category_id, soi.id, null, "")}></a>}
                      </>
                    </td>
                    <td className={soi.cancellation_date ? "text-decoration-line-through width_50px" : "width_50px"}>
                      {soi.cancellation_date
                      ?
                      <div>
                      ${soi.logo_cost}
                      </div>
                      :
                      <Form.Control type="text" 
                            className='px-1 text-center'
                            name='logo_cost'
                            disabled={props.view_only_stock_orders}
                            value={soi.logo_cost}
                            onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value )}
                            />
                      }
                    </td>
                    <td className={soi.cancellation_date ? "text-decoration-line-through width_50px" : "width_50px"}>
                      {soi.cancellation_date
                      ?
                      <div>
                      ${soi.cost}
                      </div>
                      :
                      <div className='width_70px pe-1 text-center d-flex'>
                        <Form.Control type="text" 
                              className='px-1 text-center'
                              name='cost'
                              disabled={props.view_only_stock_orders}
                              value={soi.cost}
                              onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value )} 
                              />
                      </div>
                      }
                    </td>
                    <td className={soi.cancellation_date ? "text-decoration-line-through width_50px text-center" : "width_50px text-center"}>{soi.qty}</td>
                    <td>
                      <div className={soi.cancellation_date ? "text-decoration-line-through text-center" : "text-center"} style={{ width: "70px" }}>
                      {
                      sizing_table[soc.stock_order_sizing_table_id] ? 
                        (_.sum(_.map(soi.stock_order_quantities, (q) => parseInt(q.qty) || 0)) * (parseFloat((soi.cost || 0.0))+parseFloat((soi.logo_cost || 0)))).toFixed(2)
                      :
                        ((parseFloat((soi.cost || 0.0))+parseFloat((soi.logo_cost || 0))) * parseInt(soi.qty || 0)).toFixed(2)
                      }
                      </div>
                    </td>
                    <td className="width_50px">
                      {/* <div className="dropdown">
                        <button className="btn dropdown-toggle lh-1 fs-5" aria-expanded = "false" data-bs-toggle = "dropdown" type = "button" id = {"dropdownContainerItemMenu_"+soi.id} />
                        <ul className="dropdown-menu" aria-labelledby = {"dropdownContainerItemMenu_"+soi.id}>
                          <li className="dropdown-item">
                            {soi.cancellation_date ? 
                            <a className="link" onClick={props.handleRestore} category_id={soi.category_id} qty={soi.qty} total_cost={soi.total_cost}>
                              Restore
                            </a> 
                            : ""}
                            </li>
                          <li className="dropdown-item">
                            <a className="ms-3 link color_red" onClick={props.handleRemove}>Remove</a>
                          </li>
                        </ul>
                      </div> */}
                      {
                        ((soi.sku && soi.sku.trim() !== "") || (soi.description && soi.description.trim() !== ""))
                        &&
                        <>
                          {
                          soi["id"] === (soc.stock_order_items.filter((el) => !el.deleted).slice(-1)[0]["id"])
                          &&
                          <>
                            {!props.view_only_stock_orders && <a className="link" onClick={ () => props.handleNewItem(soc.category_id) }>
                              Add
                            </a>}
                            <br/>
                          </>
                          }
                          {!soi.cancellation_date &&
                            <>
                            {!props.view_only_stock_orders && <a className="link color_purple" onClick={ () => props.handleNewItem(soc.category_id, soi.id) }>
                              Duplicate
                            </a>}
                            <br/>
                          </>}
                        </>
                      }
                      {
                        soi.cancellation_date
                        &&
                        <>
                          <a className="link color_green" onClick={() => props.handleRestore(soc.category_id, soi.id)} category_id={soi.category_id} qty={soi.qty} total_cost={parseFloat(soi.total_cost).toFixed(2)}>
                            Restore
                          </a>
                          <br/>
                        </>
                      }
                      {!props.view_only_stock_orders && <a className="link color_red" onClick={ () => props.handleRemove(soc.category_id, soi.id) }>Remove</a>}
                    </td>
                  </tr>
                ))
              }
              </>
              {sizing_table[soc.stock_order_sizing_table_id] ?
              <tr style={{backgroundColor: "rgba(0, 0, 0, 0.01)"}}>
                <td colSpan="5"><b>Total quantities by sizes:</b></td>
                <td className='row m-0'>
                  {
                  (sizing_table[soc.stock_order_sizing_table_id]).values.map((br) => (
                    (soc.total_qty_by_sizes) ?
                    <div className="width_50px pe-1 text-center">{soc.total_qty_by_sizes[br] || 0}</div>
                    :
                    <></>
                  )) 
                  }
                </td>
              </tr>
              :
              <></>
              }
            </>
          ))
        }
        </tbody>
      </Table>
      <div className='row text-end m-0'>
        <div className='p-0 fs-6'>Total Items: <b>{props.total_items}</b> Total Cost: <b>{parseFloat(props.total_cost).toFixed(2)}</b></div>
      </div>
      </>
  )
} 


let sizing_table_options = (sizing_table, soc) => {
  let sizing_table_options_arr = [{label: "One size", value: ""}]

  Object.values(sizing_table).map((st) => {
    if (!st.deleted || soc.original_stock_order_sizing_table_id === st.id)
      sizing_table_options_arr.push({label: st.name, value: st.id})
  })

  return sizing_table_options_arr
}

let attribute_options = (options, actual_value) => {
  let attribute_options_arr = []
  attribute_options_arr = options.includes(actual_value) ? [] : [{label: actual_value, value: actual_value}]

  options.map((st) => {
    attribute_options_arr.push({label: st, value: st})
  })

  return attribute_options_arr
}

export default DetailedEntry