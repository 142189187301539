import React, {useState} from 'react'
import GsgButton from "../../../shared/components/gsg_button";
import {Col, Row} from "react-bootstrap";
import LostFoundNumber from "./LostFoundNumber";
import moment from "moment/moment";
import Categories from "./Categories";
import CurrentLocations from "./CurrentLocations";


function SettingsPage(props) {

  const {
    categories,
    current_locations,

    saveGeneralSettings,

    newLocation,
    editLocation,
    saveLocation,
    createLocation,
    changeLocation,
    cancelLocation,
    removeLocation,
    updateDefaultOption,

    newCategory,
    editCategory,
    saveCategory,
    createCategory,
    changeCategory,
    cancelCategory,
    removeCategory,
  } = props;

  const initialState = {
    lfin_from: props.lfin_from,
    lfin_prefix: props.lfin_prefix,
  };

  const [state, setState] = useState(initialState);

  const {
    lfin_from,
    lfin_prefix,
  } = state;

  const handleInputChange = (name, value) => setState((prev) => ({...prev, [name]: value}));

  return (
    <div className="col-12 h-100 d-flex flex-column">
      <div className="row custom_name border-bottom border-dark">
        <div className='col p-0'>
          <h5 className="page-title ms-0">
            {" SERVICES "}
            <span className="icon-record"/>
            {" LOST AND FOUND "}
            <span className="icon-record"/>
            {" SETTINGS "}
          </h5>
        </div>
      </div>

      <Row className={'bg-white flex-grow-1 overflow-auto'} id={'lost_and_found_settings_form_container'}>
        <Col className={'h-100'}>
          <ul className={'btsp_ul'}>
            <LostFoundNumber
              lfin_from={lfin_from}
              lfin_prefix={lfin_prefix}
              inputChange={handleInputChange}
            />

            <CurrentLocations
              locations={current_locations}
              newLocation={newLocation}
              editLocation={editLocation}
              saveLocation={saveLocation}
              createLocation={createLocation}
              changeLocation={changeLocation}
              cancelLocation={cancelLocation}
              removeLocation={removeLocation}
              updateDefaultOption={updateDefaultOption}
            />

            <Categories
              categories={categories}
              newCategory={newCategory}
              editCategory={editCategory}
              saveCategory={saveCategory}
              createCategory={createCategory}
              changeCategory={changeCategory}
              cancelCategory={cancelCategory}
              removeCategory={removeCategory}
            />
          </ul>
        </Col>
      </Row>



      <div className='row bottom_buttons_bar m-0'>
        <div className="col-12 py-2 px-3">
          <GsgButton text="Save"
                     className="btn gems_custom_button gems_orange_button btn-default add-margin-left-0 float-end  me-2"
                     disabled={false}
                     onClick={() => saveGeneralSettings(state)}/>
        </div>
      </div>
    </div>
  )

}


export default SettingsPage
