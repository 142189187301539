import React from 'react'
import {Col, Form, Row} from "react-bootstrap";
function CurrentLocations(props) {

  const {
    locations,

    newLocation,
    editLocation,
    saveLocation,
    createLocation,
    changeLocation,
    cancelLocation,
    removeLocation,
    updateDefaultOption,
  } = props;

  const handleDefaultCheckbox = (id, e) => {
    updateDefaultOption(id, e.target.checked)
  };

  return (
    <li className="form_row">
      <Row>
        <Col xs={'3'} className={'label_names '}>
          <label>
            Current Location
          </label>
        </Col>

        <Col xs={'12'} sm={'9'} className={''}>
          <Row className={'description'}>
            <div className={'clear'}>
              Create locations for items to be picked up at
            </div>
            <div className={'my-2'}>
              <button
                type={'button'}
                className="btn gems_custom_button gems_orange_button btn-default"
                onClick={newLocation}
              >
                CREATE LOCATION
              </button>
            </div>
            <div className={'bold'}>
              Current Locations
            </div>

          </Row>
          <Row>
            {locations.map((location) => (
              <>
                {location.id && !location.edit &&
                  <div className={'d-flex ps-4 mb-2 align-items-center'} style={{gap: "15px"}} key={location.id}>
                    <div style={{minWidth: '300px'}}>
                      {location.name}
                    </div>
                    <Form.Check
                      name="drop_ship_checkbox"
                      value={location.default}
                      checked={location.default}
                      type='checkbox'
                      id={`location_default_${location.id}`}
                      label="Default"
                      style={{gap: "8px"}}
                      className='d-flex align-items-center'
                      onChange={(e) => handleDefaultCheckbox(location.id, e)}
                    />
                    <div>
                      <button
                        type={'button'}
                        className="btn gems_custom_button btn-default color_blue"
                        onClick={() => editLocation(location)}
                      >
                        Edit
                      </button>
                    </div>
                    <div>
                      <button
                        type={'button'}
                        className="btn gems_custom_button btn-default color_red"
                        onClick={() => removeLocation(location)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>}
                {(!location.id || location.edit) &&
                  <div className={'d-flex ps-4 mb-2'} style={{gap: "10px"}} key={location.id}>
                  <div style={{minWidth: '300px'}}>
                    <Form.Control type="text"
                                  name={'name'}
                                  value={location.edit_name !== undefined ? location.edit_name : location.name}
                                  onChange={(e) => changeLocation(location, `${location.edit ? 'edit_' : ''}name`, e.target.value)}
                                  className={'form-control form-control-sm custom_input no_color'}
                    />
                  </div>
                  <div>
                    <button
                      type={'button'}
                      className="btn gems_custom_button btn-default color_blue"
                      onClick={() => location.id ? saveLocation(location) : createLocation(location)}
                    >
                      Save
                    </button>
                  </div>
                  <div>
                    <button
                      type={'button'}
                      className="btn gems_custom_button btn-default"
                      onClick={() => cancelLocation(location)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>}
              </>
            ))}
          </Row>
        </Col>
      </Row>
    </li>
  )

}


export default CurrentLocations
