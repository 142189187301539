import React from 'react'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import {GlobalProvider} from 'shared/global_context'
import DashboardContainer from "./DashboardContainer";


const LostFoundRoot = ({items, settings, cached_member_list, cached_guest_list, cached_employee_list, cached_managers_list}) => {
  const state = {
    dashboard: {
      items: items,
      settings: settings,
      cached_member_list: cached_member_list,
      cached_guest_list: cached_guest_list,
      cached_employee_list: cached_employee_list,
      cached_managers_list: cached_managers_list,
    },
  }

  const store = configureStore(rootReducer, state)
  return (
    <GlobalProvider store={store}>
      <DashboardContainer/>
    </GlobalProvider>
  )
}

LostFoundRoot.propTypes = {}

export default LostFoundRoot
