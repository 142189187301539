import React from 'react'
import Select from "react-select";
import {Col, Form, Row} from "react-bootstrap";
import {CustomerTypeList, FieldLabels} from "./constants";
import MemberDropdown from "./customer_entity/MemberDropdown";
import EmployeeDropdown from "./customer_entity/EmployeeDropdown";
import GuestDropdown from "./customer_entity/GuestDropdown";
import DatePicker from "react-datepicker/es";
import moment from "moment/moment";

function FormBuilder(props) {
  const {
    fields_list,
    item,
    members,
    employees,
    guests,
    managers,
    categories,
    current_locations_list,

    date_format,

    handleCustomerTypeChange,
    handleInputChange,
    handleCreateEmployee,
    handleCreateGuest,
    handleDateChange,
  } = props;

  const getMarkupByField = (field) => {
    if (field.includes('_by_entity_id')) return customerMarkup(field.replace('_by_entity_id', ''))

    if (['description', 'found_location'].includes(field)) return textAreaMarkup(field)

    if (['lfin'].includes(field)) return inputTextMarkup(field)

    if (field.includes('date')) return dateFieldMarkup(field)

    if (field === 'category') return categoryListMarkup()
    if (field === 'current_location') return currentLocationListMarkup()
  }

  const capitalize = (word) => {
    return word && String(word[0]).toUpperCase() + String(word).slice(1)
  }

  const customerMarkup = (field) => {
    return <>
      <Form.Group as={Row} className="mb-3" controlId={`form${capitalize(field)}CustomerType`}>
        <Form.Label column sm="4" className="col-form-label col-form-label-sm">
          {FieldLabels[`${field}_by_entity_id`]}
        </Form.Label>
        <Col sm="8">
          <Select id={`${field}_by_entity_type`}
                  name={`${field}_by_entity_type`}
                  value={CustomerTypeList.filter(function (option) {
                    return option.value === item[`${field}_by_entity_type`];
                  })}
                  options={CustomerTypeList}
                  onChange={(e) => handleCustomerTypeChange(`${field}_by`, e.value)}/>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId={`form${capitalize(field)}Customer`}>
        <Form.Label column sm="4" className="col-form-label col-form-label-sm">
          Name
        </Form.Label>
        <Col sm="8">
          {item[`${field}_by_entity_type`] === 'member' && <MemberDropdown
            member_id={item[`${field}_by_entity_id`]}
            field={`${field}_by_entity_id`}
            members={members}
            handleInputChange={handleInputChange}
          />}

          {item[`${field}_by_entity_type`] === 'employee' && <EmployeeDropdown
            employee_id={item[`${field}_by_entity_id`]}
            field={`${field}_by_entity_id`}
            employees={employees}
            managers={managers}
            inputChange={handleInputChange}
            createEmployee={handleCreateEmployee}
          />}

          {item[`${field}_by_entity_type`] === 'guest' && <GuestDropdown
            guest_id={item[`${field}_by_entity_id`]}
            field={`${field}_by_entity_id`}
            guests={guests}
            inputChange={handleInputChange}
            createGuest={handleCreateGuest}
          />}
        </Col>
      </Form.Group>
    </>
  }

  const textAreaMarkup = (field) => {
    return <Form.Group as={Row} className="mb-3"
                       controlId={`form${field.split('_').map((word) => capitalize(word)).join('')}`}>
      <Form.Label column sm="4" className="col-form-label col-form-label-sm">
        {FieldLabels[field]}
      </Form.Label>
      <Col sm="8">
        <Form.Control as="textarea" rows={3}
                      name={field}
                      value={item[field] || ""}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}/>
      </Col>
    </Form.Group>
  }

  const inputTextMarkup = (field) => {
    return <Form.Group as={Row} className="mb-3"
                       controlId={`form${field.split('_').map((word) => capitalize(word)).join('')}`}>
      <Form.Label column sm="4" className="col-form-label col-form-label-sm">
        {FieldLabels[field]}
      </Form.Label>
      <Col sm="8">
        <Form.Control type="text"
                      name={field}
                      value={item[field] || ""}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
        />
      </Col>
    </Form.Group>
  }

  const dateFieldMarkup = (field) => {
    return <Form.Group as={Row} className="mb-3"
                       controlId={`form${field.split('_').map((word) => capitalize(word)).join('')}`}>
      <Form.Label column sm="4" className="col-form-label col-form-label-sm">
        {FieldLabels[field]}
      </Form.Label>
      <Col sm="8">
        <DatePicker className="form-control"
                    name={field}
                    id={field}
                    popperPlacement="bottom-start"
                    onChange={(e) => handleDateChange(field, e)}
                    selected={Date.parse(moment(item[field]))}
                    value={Date.parse(moment(item[field]))}
                    dateFormat={date_format.replaceAll('m', 'M')}
                    autoComplete="off"/>
      </Col>
    </Form.Group>
  }

  const categoryListMarkup = () => {
    return <Form.Group as={Row} className="mb-3" controlId="formCateogry">
      <Form.Label column sm="4" className="col-form-label col-form-label-sm">
        Item Category
      </Form.Label>
      <Col sm="8">
        <Select id="category_id"
                name='category_id'
                value={categories.filter(function (option) {
                  return option.value === item.category_id;
                })}
                options={categories}
                onChange={(e) => handleInputChange('category_id', e.value)}/>
      </Col>
    </Form.Group>
  }

  const currentLocationListMarkup = () => {
    return <Form.Group as={Row} className="mb-3" controlId="formCurrentLocation">
      <Form.Label column sm="4" className="col-form-label col-form-label-sm">
        Current Location
      </Form.Label>
      <Col sm="8">
        <Select id="current_location_id"
                name='current_location_id'
                value={current_locations_list.filter(function (option) {
                  return option.value === item.current_location_id;
                })}
                options={current_locations_list}
                onChange={(e) => handleInputChange('current_location_id', e.value)}/>
      </Col>
    </Form.Group>
  }


  return (<>
    {fields_list.map((field) => getMarkupByField(field))}
  </>)
}

export default FormBuilder;
