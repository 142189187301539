import React from 'react'

function Header(props) {

  return (
    <div className="row custom_name border-bottom border-dark">
      <div className='col p-0'>
        <h5 className="page-title ms-0">
          {" SERVICES "}
          <span className="icon-record"/>
          {" LOST AND FOUND "}
          <span className="icon-record"/>
          {" DASHBOARD "}
        </h5>
      </div>

    </div>

  )
}

export default Header
