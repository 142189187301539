import React from 'react'
import Select from "react-select";

function MemberDropdown(props) {
  const {
    members,
    field,
    member_id,

    handleInputChange,
  } = props

  return (
    <Select id={field}
            name={field}
            value={members.filter(function (option) {
              return option.value === member_id;
            })}
            options={members}
            onChange={(e) => handleInputChange(field, e.value)}/>
  )
}

export default MemberDropdown
