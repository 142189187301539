import React, {useEffect} from 'react'

import rootReducer from '../../settings/reducers'
import SettingsContainer from "./SettingsContainer";
import configureStore from "../../../shared/configure_store";
import {GlobalProvider} from "../../../shared/global_context";


const LostFoundSettingsRoot = ({general, current_locations, categories}) => {
  const state = {
    settings: {
      general: general,
      categories: categories,
      current_locations: current_locations,
    },
  }

  const store = configureStore(rootReducer, state)
  return (
    <GlobalProvider store={store}>
      <SettingsContainer/>
    </GlobalProvider>
  )
}

LostFoundSettingsRoot.propTypes = {}

export default LostFoundSettingsRoot
