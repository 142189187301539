import React from 'react'
import {Table} from 'react-bootstrap';
import HeaderTable from "./HeaderTable";
import ItemRow from "./ItemRow";

function ListBody(props) {
  const {
    items,
    in_fields,
    fields_column_mapping,
    sort_column,
    sort_direction,

    openListViewOptionalFieldsModal,
    updateListViewSorting,

    editItem,
    transitionAction,
    openClaimItem,
    openReturnToOwnerItem,
    openMarkAsFoundItem,
  } = props


  return (<div className='col-12 h-100 p-0'>
      <div className="table-responsive tableFixHead h-100">
        <Table striped className="table fields_table align-middle background_white table-striped">
          <HeaderTable
            in_fields={in_fields}
            fields_column_mapping={fields_column_mapping}
            sort_column={sort_column}
            sort_direction={sort_direction}
            openListViewOptionalFieldsModal={openListViewOptionalFieldsModal}
            updateListViewSorting={updateListViewSorting}
          />

          <tbody>
          {
            items.length === 0 && <tr>
              <td></td>
              <td colSpan={in_fields.length} className={'ps-4'}>
                No items
              </td>
            </tr>
          }
          {items.map((lost_found_item, index) => <ItemRow
              key={index}
              item={lost_found_item}
              in_fields={in_fields}
              index={index}
              editItem={editItem}
              transitionAction={transitionAction}
              openClaimItem={openClaimItem}
              openReturnToOwnerItem={openReturnToOwnerItem}
              openMarkAsFoundItem={openMarkAsFoundItem}
            />
          )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ListBody
