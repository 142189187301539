import {ActionTypes} from '../actions'
import {ViewFilter} from "../components/constants";

const {
  OPEN_LIST_VIEW_ADDITIONAL_FIELDS,
  UPDATE_LIST_VIEW_OPTIONAL_FIELDS,
  CLOSE_OPTIONAL_FIELDS_MODAL,
  UPDATE_LIST_VIEW_SORTING,
  FETCH_LOST_AND_FOUND_DATA_REQUEST,
  CHANGE_VIEW_FILTER,
  NEW_LOST_ITEM,
  NEW_FOUND_ITEM,
  CLOSE_ITEM_MODAL,
  CREATE_ITEM,
  EDIT_ITEM,
  UPDATE_ITEM,
  CREATE_EMPLOYEE,
  CREATE_GUEST,
  TRANSITION,
  OPEN_CLAIM_ITEM_MODAL,
  CLOSE_CLAIM_ITEM_MODAL,
  OPEN_RETURN_TO_OWNER_ITEM_MODAL,
  CLOSE_RETURN_TO_OWNER_ITEM_MODAL,
  OPEN_MARK_AS_FOUND_ITEM_MODAL,
  CLOSE_MARK_AS_FOUND_ITEM_MODAL,
} = ActionTypes

const initialState = {
  items: [], ui_state: {
    sort_by: [],
    search_term: "",
    advanced_filters: {},
    view_filter: ViewFilter.LOST_AND_FOUND.value,

    selected_item: null,
    manage_item_entry: '',

    modal_configure_fields_is_open: false,
    modal_manage_item_is_open: false,
    modal_claim_item_is_open: false,
    modal_return_to_owner_item_is_open: false,
    modal_mark_as_found_item_is_open: false,
  }, app_state: {
    is_loading: false,
  }, settings: {}, cached_member_list: [], cached_employee_list: [], cached_guest_list: [], cached_managers_list: [],
}

const dashboard = (state = initialState, action) => {
  let item = null;
  let items_list = null;
  switch (action.type) {
    case FETCH_LOST_AND_FOUND_DATA_REQUEST:
      return {
        ...state, settings: {
          ...state.settings, in_fields: action.data.in_fields, fields_column_mapping: action.data.fields_column_mapping,
        }, items: action.data.items,
      }

    case OPEN_LIST_VIEW_ADDITIONAL_FIELDS:
      return {
        ...state, settings: {
          ...state.settings,
          fields: action.data.fields,
          in_fields: action.data.in_fields,
          mandatory_fields: action.data.mandatory_fields,
        }, ui_state: {
          ...state.ui_state, modal_configure_fields_is_open: true,
        },
      }

    case UPDATE_LIST_VIEW_OPTIONAL_FIELDS:
      return {
        ...state, settings: {
          ...state.settings, fields: JSON.parse(action.data.fields), in_fields: JSON.parse(action.data.in_fields),
        }, ui_state: {
          ...state.ui_state, modal_configure_fields_is_open: false,
        },

      }

    case CLOSE_OPTIONAL_FIELDS_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state, modal_configure_fields_is_open: false,
        },
      }

    case UPDATE_LIST_VIEW_SORTING:
      return {
        ...state, list_sort_column: action.data.sort_column, list_sort_direction: action.data.sort_direction
      }

    case CHANGE_VIEW_FILTER:
      return {
        ...state, ui_state: {...state.ui_state, view_filter: action.data.view},
      }

    case NEW_LOST_ITEM:
      return {
        ...state, ui_state: {
          ...state.ui_state,
          manage_item_entry: 'lost',
          selected_item: action.data.item,
          modal_manage_item_is_open: true,
        },
      }

    case NEW_FOUND_ITEM:
      return {
        ...state, ui_state: {
          ...state.ui_state,
          manage_item_entry: 'found',
          selected_item: action.data.item,
          modal_manage_item_is_open: true,
        },
      }

    case CLOSE_ITEM_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state, modal_manage_item_is_open: false,
        },
      }
    case CREATE_ITEM:
      return {
        ...state, items: [...state.items, action.data.item], ui_state: {
          ...state.ui_state, manage_item_entry: null, selected_item: null, modal_manage_item_is_open: false,
        }, app_state: {
          ...state.app_state,
        },
      }
    case EDIT_ITEM:
      return {
        ...state, ui_state: {
          ...state.ui_state,
          manage_item_entry: 'edit',
          selected_item: action.data.item,
          modal_manage_item_is_open: true,
        },
      }
    case UPDATE_ITEM:
      return {
        ...state,
      }

    case CREATE_EMPLOYEE:
      item = state.ui_state.selected_item
      item[action.data.field] = action.data.employee.id
      return {
        ...state, ui_state: {
          ...state.ui_state, selected_item: item,
        }, cached_employee_list: action.data.employees,
      }

    case CREATE_GUEST:
      item = state.ui_state.selected_item
      item[action.data.field] = action.data.guest.id
      return {
        ...state, ui_state: {
          ...state.ui_state, selected_item: item,
        }, cached_guest_list: action.data.guests,
      }
    case TRANSITION:
      items_list = [...state.items].filter(function (item) {
        return item.id !== action.data.item.id
      })
      items_list.push(action.data.item)
      return {
        ...state, items: items_list, ui_state: {
          ...state.ui_state,
          modal_claim_item_is_open: false,
          modal_return_to_owner_item_is_open: false,
          modal_mark_as_found_item_is_open: false,
        }, app_state: {
          ...state.app_state,
        },
      }

    case OPEN_CLAIM_ITEM_MODAL:
      item = [...state.items].filter(function (item) {
        return item.id === action.data.id
      })[0]
      return {
        ...state, ui_state: {
          ...state.ui_state, selected_item: item, modal_claim_item_is_open: true,
        },
      }
    case CLOSE_CLAIM_ITEM_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state, modal_claim_item_is_open: false,
        }, app_state: {
          ...state.app_state,
        },
      }

    case OPEN_RETURN_TO_OWNER_ITEM_MODAL:
      item = [...state.items].filter(function (item) {
        return item.id === action.data.id
      })[0]
      return {
        ...state, ui_state: {
          ...state.ui_state, selected_item: item, modal_return_to_owner_item_is_open: true,
        },
      }
    case CLOSE_RETURN_TO_OWNER_ITEM_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state, modal_return_to_owner_item_is_open: false,
        },
      }

    case OPEN_MARK_AS_FOUND_ITEM_MODAL:
      item = [...state.items].filter(function (item) {
        return item.id === action.data.id
      })[0]
      return {
        ...state, ui_state: {
          ...state.ui_state, selected_item: item, modal_mark_as_found_item_is_open: true,
        },
      }
    case CLOSE_MARK_AS_FOUND_ITEM_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state, modal_mark_as_found_item_is_open: false,
        },

      }
    default:
      return {
        ...initialState, ...state,
      }
  }
}

export default dashboard