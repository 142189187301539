import {connect} from 'react-redux'
import Body from "../components/Body";


const mapStateToProps = (state) => ({
  modal_configure_fields_is_open: state.dashboard.ui_state.modal_configure_fields_is_open,
  modal_manage_item_is_open: state.dashboard.ui_state.modal_manage_item_is_open,
  modal_claim_item_is_open: state.dashboard.ui_state.modal_claim_item_is_open,
  modal_return_to_owner_item_is_open: state.dashboard.ui_state.modal_return_to_owner_item_is_open,
  modal_mark_as_found_item_is_open: state.dashboard.ui_state.modal_mark_as_found_item_is_open,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Body)