import {connect} from 'react-redux'
import {closeItemModal, createEmployee, createGuest, createItem, updateItem} from '../../actions'
import ManageItemModal from "../../components/actions_modals/ManageItemModal";

const mapStateToProps = (state) => ({

  date_format: state.dashboard.settings.date_format,
  customer_id: state.dashboard.settings.customer_id,

  item: state.dashboard.ui_state.selected_item,
  entry: state.dashboard.ui_state.manage_item_entry,
  modal_manage_item_is_open: state.dashboard.ui_state.modal_manage_item_is_open,


  members: state.dashboard.cached_member_list,
  employees: state.dashboard.cached_employee_list,
  guests: state.dashboard.cached_guest_list,
  managers: state.dashboard.cached_managers_list,
  categories: state.dashboard.settings.categories,
  current_locations_list: state.dashboard.settings.current_locations,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeItemModal()),
  createItem: (data) => dispatch(createItem(data)),
  updateItem: data => dispatch(updateItem(data)),
  createEmployee: (data, field) => dispatch(createEmployee(data, field)),
  createGuest: data => dispatch(createGuest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageItemModal)
