import {ActionTypes} from '../actions'

const {
  NEW_LOCATION,
  EDIT_LOCATION,
  SAVE_LOCATION,
  CREATE_LOCATION,
  CHANGE_LOCATION,
  CANCEL_LOCATION,
  REMOVE_LOCATION,
  UPDATE_LOCATIONS,
  NEW_CATEGORY,
  EDIT_CATEGORY,
  SAVE_CATEGORY,
  CREATE_CATEGORY,
  CHANGE_CATEGORY,
  CANCEL_CATEGORY,
  REMOVE_CATEGORY,
} = ActionTypes

const initialState = {
  current_locations: [],
  categories: [],
  app_state: {
    is_loading: false,
  },
  ui_state: {
  },
}

const settings = (state = initialState, action) => {
  let current_locations_list;
  let categories_list;
  let category_aux;
  let category_index;
  let current_location_aux;
  let current_location_index;

  switch (action.type) {
    case NEW_LOCATION:
      let new_location = {
        id: null,
        name: '',
        default: false,
      };
      return {
        ...state, current_locations: [new_location, ...state.current_locations]
      }

    case EDIT_LOCATION:
      current_locations_list = [...state.current_locations]
      current_location_index = current_locations_list.findIndex((e) => e.id === action.data.location.id)
      current_locations_list[current_location_index].edit = true
      return {
        ...state, current_locations: current_locations_list
      }

    case CREATE_LOCATION:
      current_locations_list = [...state.current_locations].filter((e) => e !== action.data.old_location)
      current_locations_list.push(action.data.new_location)
      return {
        ...state, current_locations: current_locations_list
      }

    case SAVE_LOCATION:
      current_locations_list = [...state.current_locations].filter((e) => e.id !== action.data.location.id)
      current_locations_list.push(action.data.location)
      return {
        ...state, current_locations: current_locations_list
      }

    case CHANGE_LOCATION:
      current_locations_list = [...state.current_locations]
      current_location_index = current_locations_list.findIndex((e) => e.id === action.data.location.id)
      current_locations_list[current_location_index][action.data.field] = action.data.value
      return {
        ...state, current_locations: current_locations_list
      }

    case CANCEL_LOCATION:
      current_locations_list = [...state.current_locations].filter((e) => e !== action.data.location)
      if (action.data.location.id) {
        current_location_aux = action.data.location
        delete current_location_aux.edit
        delete current_location_aux.edit_name
        current_locations_list.push(current_location_aux)
      }
      return {
        ...state, current_locations: current_locations_list
      }

    case REMOVE_LOCATION:
      current_locations_list = [...state.current_locations].filter((e) => e.id !== action.data.location.id)
      return {
        ...state, current_locations: current_locations_list
      }

    case UPDATE_LOCATIONS:
      return {
        ...state, current_locations: action.data.locations
      }



    case NEW_CATEGORY:
      let new_category = {
        id: null,
        name: '',
      };
      return {
        ...state, categories: [new_category, ...state.categories]
      }

    case EDIT_CATEGORY:
      categories_list = [...state.categories]
      category_index = categories_list.findIndex((e) => e.id === action.data.category.id)
      categories_list[category_index].edit = true
      return {
        ...state, categories: categories_list
      }

    case CREATE_CATEGORY:
      categories_list = [...state.categories].filter((e) => e !== action.data.old_category)
      categories_list.push(action.data.new_category)
      return {
        ...state, categories: categories_list
      }

    case SAVE_CATEGORY:
      categories_list = [...state.categories].filter((e) => e.id !== action.data.category.id)
      categories_list.push(action.data.category)
      return {
        ...state, categories: categories_list
      }

    case CHANGE_CATEGORY:
      categories_list = [...state.categories]
      category_index = categories_list.findIndex((e) => e.id === action.data.category.id)
      categories_list[category_index][action.data.field] = action.data.value
      return {
        ...state, categories: categories_list
      }

    case CANCEL_CATEGORY:
      categories_list = [...state.categories].filter((e) => e !== action.data.category)
      if (action.data.category.id) {
        category_aux = action.data.category
        delete category_aux.edit
        delete category_aux.edit_name
        categories_list.push(category_aux)
      }
      return {
        ...state, categories: categories_list
      }

    case REMOVE_CATEGORY:
      categories_list = [...state.categories].filter((e) => e.id !== action.data.category.id)
      return {
        ...state, categories: categories_list
      }

    default:
      return {
        ...initialState, ...state,
      }
  }
}

export default settings