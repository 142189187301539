import React from 'react'
import {ActionsConfig, QuickActionByStatus, StatusLabels} from "./constants";

function ItemStatus(props) {
  const {
    item,
    transitionQuickAction
  } = props

  const shouldDisplayQuickAction = () => {
    return item.status in QuickActionByStatus
  }

  return (<div className="d-flex justify-content-between" style={{gap: "8px"}}>
    <label
      className="order-0 basic_label_container white mb-1 mx-1 tooltip_delivered"
      style={{backgroundColor: StatusLabels[item.status]?.color || ''}}
    >
      {StatusLabels[item.status]?.label || ''}
    </label>
    {shouldDisplayQuickAction() ? <span
      className="order-2 btn_icons_datatable padding_forward link next float-end standard me-2"
      onClick={(e) => transitionQuickAction(item, QuickActionByStatus[item.status], {})}
      id={item.id}
      data-toggle="tooltip"
      data-bs-original-title={ActionsConfig[QuickActionByStatus[item.status]]?.label || ''}
      aria-label={ActionsConfig[QuickActionByStatus[item.status]]?.label || ''}
    >
        <i className="font_size_larger fa fa-forward mt-1"/>
      </span> : <span
      className="order-2 btn_icons_datatable padding_forward link next float-end standard me-2"
      id={item.id}
    >
        <i className="font_size_larger fa fa-forward mt-1 color_gray"/>
      </span>}

  </div>)
}

export default ItemStatus
