import {connect} from 'react-redux'
import ViewFilterComponent from "../components/ViewFilterComponent";
import {changeViewFilter} from "../actions";


const mapStateToProps = (state) => ({
  view_filter: state.dashboard.ui_state.view_filter,
})

const mapDispatchToProps = (dispatch) => ({
  changeViewFilter: (view) => dispatch(changeViewFilter(view))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewFilterComponent)