import React from 'react'
import {Col, Form, Row} from "react-bootstrap";

function LostFoundNumber(props) {

  const {
    lfin_from,
    lfin_prefix,

    inputChange
  } = props;

  return (
    <li className="form_row">
      <Row>
        <Col xs={'3'} className={'label_names '}>
          <label>
            Lost and Found Item Number
          </label>
        </Col>

        <Col xs={'12'} sm={'9'} className={'label_names '}>
          <Row className={'description'}>
            <span className={'bold'}>
              LFIN# starting value
            </span>
            <span className={'clear'}>
              The LFIN# value appears on the Lost and Found Dashboard, Emails and Reports. You can always override this number when creating a new Lost or Found item.
            </span>
          </Row>

          <Row className={'content_fields m-0 mb-3'} style={{width: "75px"}}>
            <Col className={'p-0'}>
              <Form.Control type="number"
                            name={'lfin_from'}
                            value={lfin_from}
                            onChange={(e) => inputChange(e.target.name, e.target.value)}
                            className={'form-control form-control-sm custom_input no_color'}
              />
            </Col>
          </Row>

          <Row className={'description'}>
            <span className={'bold'}>
              LFIN# Prefix
            </span>
            <span className={'clear'}>
              The LFIN# Prefix appears before the LFIN#
            </span>
          </Row>

          <Row className={'content_fields m-0 mb-3'} style={{width: "75px"}}>
            <Col className={'p-0'}>
              <Form.Control type="text"
                            name={'lfin_prefix'}
                            value={lfin_prefix}
                            onChange={(e) => inputChange(e.target.name, e.target.value)}
                            className={'form-control form-control-sm custom_input no_color'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </li>
  )

}


export default LostFoundNumber
