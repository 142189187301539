import React, {useState} from 'react'
import Select from "react-select";
import {Col, Form, OverlayTrigger, Row, Tooltip} from "react-bootstrap";

function EmployeeDropdown(props) {
  const {
    employees, managers, field, employee_id,

    inputChange,
    createEmployee,
  } = props;

  const initialState = {
    create_section_is_displayed: false,
    is_user: true,
    manager: {
      id: null,
      phone: '',
    },
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  }
  const [state, setState] = useState(initialState);

  const {
    create_section_is_displayed, is_user, manager, first_name, last_name, email, phone
  } = state
  const handleClickNewEmployee = (event) => {
    setState({...state, create_section_is_displayed: true});
  }

  const handleChangeIsUser = (event) => {
    setState({...state, is_user: event.target.name === 'user_yes'});
  }
  const handleManagerChange = (e) => {
    setState({...state, manager: {id: e.value, phone: e.phone}});
  }

  const handleManagerCellPhoneChange = (e) => {
    setState({...state, manager: {...manager, phone: e.target.value}});
  }

  const handleInputChange = (name, value) => setState((prev) => ({...prev, [name]: value}));

  const handleClose = (event) => setState(initialState);

  const handleSaveEmployee = (e) => {
    e.preventDefault();
    const validationErrors = validateFields();

    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((message, index) => window.gems.toast_message.init(message, "warning"))
    } else {
      console.log("Staff member submitted successfully", state);
      createEmployee({
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
        user_id: manager.id,
        manager_phone: manager.phone,
      }, field);
      handleClose()
    }
  }

  const validateFields = () => {
    const rules = [
      {
        condition: is_user && !manager.id,
        field: "manager",
        message: "Please select a manager before saving!",
      },
      {
        condition: !is_user && !first_name,
        field: "first_name",
        message: "First Name is required",
      },
      {
        condition: !is_user && !last_name,
        field: "last_name",
        message: "Last Name is required",
      },
      {
        condition: !is_user && !email,
        field: "email",
        message: "Email is required",
      },
    ];

    return rules.reduce((errors, {condition, field, message}) => {
      if (condition) {
        errors[field] = message;
      }
      return errors;
    }, {});
  };


  return (<>
    <Select id={field}
            name={field}
            value={employees.filter(function (option) {
              return option.value === employee_id;
            })}
            options={employees}
            onChange={(e) => inputChange(field, e.value)}/>
    {!create_section_is_displayed && <>
      <label onClick={handleClickNewEmployee} className='mt-2 link'>
        Add new Staff Member
      </label>
    </>}

    {create_section_is_displayed && <>
      <Row className='mx-0 my-2 py-2 shop_user_container border rounded'>
        <Col style={{gap: "8px"}} className='d-flex flex-column'>
          <Row>
            <Col xs={4}>
              <label className='form-check-label'>Golf Shop User</label>
            </Col>
            <Col xs={8}>
              <Row>
                <Col xs={6} className='form-check'>
                  <input className='form-check-input' id={'user_yes'} name={'user_yes'} checked={is_user}
                         type={'radio'} onClick={handleChangeIsUser}/>
                  <label className='form-check-label ps-2' for={'user_yes'}>Yes</label>
                </Col>
                <Col xs={6} className='form-check'>
                  <input className='form-check-input' id={'user_no'} name={'user_no'} checked={!is_user}
                         type={'radio'} onClick={handleChangeIsUser}/>
                  <label className='form-check-label ps-2' for={'user_no'}>No</label>
                </Col>
              </Row>
            </Col>
          </Row>
          {is_user && <>
            <Form.Group as={Row} className="" controlId="formEmployeeCustomerSelect">
              <Form.Label column sm="4" className="col-form-label col-form-label-sm">
                Select User
              </Form.Label>
              <Col sm="8">
                <Select id="managers"
                        name='managers'
                        value={managers.filter(function (option) {
                          return option.value === manager.id;
                        })}
                        options={managers}
                        onChange={(e) => handleManagerChange(e)}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="" controlId="formEmployeeCustomerCellPhone">
              <Form.Label column sm="4" className="col-form-label col-form-label-sm">
                Cell Phone
              </Form.Label>
              <Col sm="7">
                <Form.Control type="text"
                              name='manager_cell_phone'
                              value={manager.phone}
                              onChange={handleManagerCellPhoneChange}
                />
              </Col>
              <Col sm="1" className='d-flex align-items-center ps-0'>
                <OverlayTrigger
                  key="manager_cell_phone"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-manager_cell_phone`}>
                      Cell Phone Number is needed to receive automated text notifications
                    </Tooltip>
                  }
                >
                  <i className=" align-middle gga-info-circle tooltip_info"/>
                </OverlayTrigger>
              </Col>
            </Form.Group>
          </>}

          {!is_user && <>
            <Form.Group as={Row} className="" controlId="formEmployeeCustomerFirstName">
              <Form.Label column sm="4" className="col-form-label col-form-label-sm">
                First Name
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text"
                              name='first_name'
                              value={first_name}
                              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="" controlId="formEmployeeCustomerLastName">
              <Form.Label column sm="4" className="col-form-label col-form-label-sm">
                Last Name
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text"
                              name='last_name'
                              value={last_name}
                              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="" controlId="formEmployeeCustomerEmail">
              <Form.Label column sm="4" className="col-form-label col-form-label-sm">
                Email
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text"
                              name='email'
                              value={email}
                              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="" controlId="formEmployeeCustomerPhone">
              <Form.Label column sm="4" className="col-form-label col-form-label-sm">
                Cell Phone
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text"
                              name='phone'
                              value={phone}
                              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                />
              </Col>
            </Form.Group>
          </>}

          <Row className='mx-0'>
            <Col className='py-2 border-top'>
              <button type={'button'} className={'btn gems_custom_button float-start me-2'}
                      onClick={handleClose}>Close
              </button>
              <button type={'button'} className={'btn gems_custom_button gems_orange_button float-end me-2'}
                      onClick={handleSaveEmployee}>Save
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>}
  </>)
}

export default EmployeeDropdown
