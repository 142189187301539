import React, {useState} from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader} from '../Modal';
import {Form} from 'react-bootstrap';
import Summary from "../Summary";
import moment from 'moment';
import {validateFields} from "../../actions";
import FormBuilder from "../FormBuilder";


function ManageItemModal(props) {

  const {
    customer_id,
    date_format,
    members,
    employees,
    guests,
    managers,
    categories,
    current_locations_list,

    modal_manage_item_is_open,
    entry,

    closeModal,
    createItem,
    updateItem,
    createEmployee,
    createGuest,
  } = props;

  const initialState = {
    ...props.item,
    entered_by: props.item?.entered_by?.id,
    lost_date: entry === 'lost' ? moment() : props.item.lost_date,
    found_date: entry === 'found' ? moment() : props.item.found_date,
  };

  const [state, setState] = useState(initialState);

  const {
    files,
  } = state;

  const required_fields = [
    ...entry === 'lost' ? ['lost_date', 'lost_by_entity_id'] : [],
    ...entry === 'found' ? ['found_date', 'found_by_entity_id'] : [],
    ...entry === 'edit' && props.item.status.includes('LOST') ? ['lost_date', 'lost_by_entity_id'] : [],
    ...entry === 'edit' && props.item.status.includes('FOUND') ? ['found_date', 'found_by_entity_id'] : [],
    'lfin',
  ]

  const fields_list = [
    ...(entry === 'lost' || (entry === 'edit' && props.item.status.includes('LOST')) ? ['lost_date', 'lost_by_entity_id'] : []),
    ...(entry === 'found' || (entry === 'edit' && props.item.status.includes('FOUND')) ? ['found_date', 'found_by_entity_id'] : []),
    'description',
    'lfin',
    'category',
    ...(entry === 'found' || entry === 'edit' && props.item.status.includes('FOUND') ? ['found_location', 'current_location'] : []),
    ...(entry === 'edit' && (props.item.status.includes('FOUND_CLAIMED') || props.item.status.includes('FOUND_RETURNED_TO_OWNER')) ? ['claimed_date', 'claimed_by_entity_id'] : []),
    ...(entry === 'edit' && props.item.status.includes('FOUND_RETURNED_TO_OWNER') ? ['pickup_date'] : []),
  ]

  const handleClose = () => closeModal();

  const handleInputChange = (name, value) => setState((prev) => ({...prev, [name]: value}));

  const handleCustomerTypeChange = (field, value) => {
    setState((prev) => ({...prev, [`${field}_entity_type`]: value, [`${field}_entity_id`]: null}));
  }

  const handleDateChange = (name, date) => handleInputChange(name, date);


  const handleCreateEmployee = (fields, entity_name) => {
    createEmployee(fields, entity_name).then((response => {
      setState({...state, [entity_name]: response})
    }))
  }

  const handleCreateGuest = (fields, entity_name) => {
    createGuest(fields, entity_name).then((response => {
      setState({...state, [entity_name]: response})
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFields(state, required_fields, entry === 'edit');

    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((message, index) => window.gems.toast_message.init(message, "warning"))
    } else {
      console.log("Form submitted successfully", state);
      if (entry === 'edit') {
        updateItem(state);
      } else {
        createItem(state);
      }

    }
  };

  const title = entry === "lost" ? "NEW LOST ITEM" : entry === "found" ? "NEW FOUND ITEM" : "EDIT ITEM";
  const submitButtonName = entry === "lost" || entry === "found" ? "Create" : "Update";

  return (<div className="modal_lost_item_container">
    <Modal isOpen={modal_manage_item_is_open} scrollable={true} className="lostItemModal"
           dialogClassName="modal-dialog-centered">
      <ModalHeader>
        <div className="row">
          <div className="col">
            <h4 className="modal-title form_new_edit no-margin">{title}</h4>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Form id="LostItemForm">
          <div className="row h-auto">
            <div className="col pt-2 overflow-auto min-height-body-modal mb-2">
              <FormBuilder
                fields_list={fields_list}
                item={state}
                members={members}
                employees={employees}
                guests={guests}
                managers={managers}
                categories={categories}
                current_locations_list={current_locations_list}
                date_format={date_format}

                handleCustomerTypeChange={handleCustomerTypeChange}
                handleInputChange={handleInputChange}
                handleCreateEmployee={handleCreateEmployee}
                handleCreateGuest={handleCreateGuest}
                handleDateChange={handleDateChange}
              />


            </div>
            <div className="col pt-2 h-auto overflow-auto background_grey summary_container min-height-body-modal">
              <Summary customer_id={customer_id}
                       state={state}
                       isRestricted={false}
                       btn_name={submitButtonName || null}
                       files={files}
                       notification_communication_data={{}}
                       handleInputChange={handleInputChange}
                // uploadFile={uploadFile}
              />
            </div>
          </div>


        </Form>
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button"
          aria-label="Close"
          onClick={handleClose}
        >
          Close
        </button>

        <button
          className={`btn gems_custom_button gems_orange_button float-end ${entry === 'edit' ? 'disabled' : ''}`}
          onClick={(event) => handleSubmit(event)}
        >
          {submitButtonName}
        </button>
      </ModalFooter>
    </Modal>
  </div>);
}

export default ManageItemModal