import {connect} from 'react-redux'
import ListViewOptionalFieldsModal from "../components/ListViewOptionalFieldsModal";
import {closeOptionalFieldsModal, updateListViewOptionalFields} from "../actions";

const mapStateToProps = (state) => ({
  modal_configure_fields_is_open: state.dashboard.ui_state.modal_configure_fields_is_open,
  fields: state.dashboard.settings.fields,
  in_fields: state.dashboard.settings.in_fields,
  mandatory_fields: state.dashboard.settings.mandatory_fields
})

const mapDispatchToProps = (dispatch) => ({
  closeOptionalFieldsModal: () => dispatch(closeOptionalFieldsModal()),
  updateListViewOptionalFields: data => dispatch(updateListViewOptionalFields(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListViewOptionalFieldsModal)
