import {connect} from 'react-redux'
import {closeClaimItemModal, createEmployee, createGuest, transitionAction} from '../../actions'
import ClaimItemModal from "../../components/actions_modals/ClaimItemModal";

const mapStateToProps = (state) => ({
  date_format: state.dashboard.settings.date_format,
  customer_id: state.dashboard.settings.customer_id,
  item: state.dashboard.ui_state.selected_item,

  modal_claim_item_is_open: state.dashboard.ui_state.modal_claim_item_is_open,

  members: state.dashboard.cached_member_list,
  employees: state.dashboard.cached_employee_list,
  guests: state.dashboard.cached_guest_list,
  managers: state.dashboard.cached_managers_list,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeClaimItemModal()),
  createEmployee: (data, field) => dispatch(createEmployee(data, field)),
  createGuest: data => dispatch(createGuest(data)),
  transitionAction: (item, transition, params) => dispatch(transitionAction(item, transition, params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClaimItemModal)
