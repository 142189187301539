import React from 'react'
import {Col, Row} from "react-bootstrap";
import Select from "react-select";
import {ViewFilter} from "./constants";

function ViewFilterComponent(props) {

  const {
    view_filter,

    changeViewFilter,
  } = props;

  const handleViewFilterChange = (event) => {
    changeViewFilter(event.value)
  }

  return (
    <div className="row bg-white" id="container_status_view">
      {
        <div className="col-6 py-2">
          <Row className="justify-content-start align-items-center">
            <b className="col-2 col-xl-1 col-form-label col-form-label-sm" for={"status_view_lost_found_items"}>
              View
            </b>
            <Col xs={7} style={{ zIndex: 200, maxWidth: "350px" }}>
              <Select id="view_status"
                      name='view_status'
                      value={Object.values(ViewFilter).filter(function (option) {
                        return option.value === view_filter;
                      })}
                      options={Object.values(ViewFilter)}
                      onChange={handleViewFilterChange} />
            </Col>
          </Row>
        </div>
      }
    </div>
  )
}

export default ViewFilterComponent
