export const ViewFilter = {
  LOST_AND_FOUND: {label: "Lost and Found Items", value: "LOST_AND_FOUND"},
  LOST: {label: "Lost Items", value: "LOST"},
  FOUND: {label: "Found Items", value: "FOUND"},
}


export const CustomerTypeList = [
  {value: 'member', label: 'Member'},
  {value: 'guest', label: 'Guest'},
  {value: 'employee', label: 'Staff'},
];


export const StatusLabels = {
  LOST_CLAIMED: {
    label: 'Lost',
    color: 'red',
  },
  LOST_CLAIMED_DELETED: {
    label: 'Lost and Deleted',
    color: 'red',
  },
  FOUND_UNCLAIMED: {
    label: 'Found and Unclaimed',
    color: 'black',
  },
  FOUND_UNCLAIMED_DELETED: {
    label: 'Found and Deleted',
    color: 'red',
  },
  FOUND_CLAIMED: {
    label: 'Found and Claimed',
    color: 'orange',
  },
  FOUND_CLAIMED_DELETED: {
    label: 'Found and Deleted',
    color: 'red',
  },
  FOUND_RETURNED_TO_OWNER: {
    label: 'Found and Returned to Owner',
    color: 'green',
  },
  FOUND_RETURNED_TO_OWNER_DELETED: {
    label: 'Found and Deleted',
    color: 'red',
  },
};

export const DropdownActionsByStatus = {
  LOST_CLAIMED: ['mark_as_found', 'send_email', 'edit', 'remove_item'],
  LOST_CLAIMED_DELETED: ['restore_item'],
  FOUND_UNCLAIMED: ['claim_item', 'return_to_owner', 'send_email', 'edit', 'remove_item'],
  FOUND_UNCLAIMED_DELETED: ['restore_item'],
  FOUND_CLAIMED: ['return_to_owner', 'send_email', 'edit', 'remove_item', 'revert_to_previous_state'],
  FOUND_CLAIMED_DELETED: ['restore_item'],
  FOUND_RETURNED_TO_OWNER: ['edit', 'remove_item', 'revert_to_previous_state'],
  FOUND_RETURNED_TO_OWNER_DELETED: ['restore_item'],
};

export const QuickActionByStatus = {
  FOUND_CLAIMED: ['return_to_owner'],
};

export const ActionsConfig = {
  edit: {
    label: 'Edit', icon: 'fa fa-pencil', colorClass: 'color_black',
  },
  send_email: {
    label: 'Send Email', icon: 'icon-menu-communication-bold', colorClass: 'color_black',
  },
  remove_item: {
    label: 'Delete', icon: 'fa fa-close', colorClass: 'color_red',
  },
  restore_item: {
    label: 'Restore', icon: 'icon-ccw', colorClass: 'color_green',
  },
  mark_as_found: {
    label: 'Mark as Found', icon: 'fa fa-forward', colorClass: 'color_black',
  },
  claim_item: {
    label: 'Found and Claimed', icon: 'fa fa-forward', colorClass: 'color_black',
  },
  return_to_owner: {
    label: 'Return to Owner', icon: 'fa fa-forward', colorClass: 'color_black',
  },
  revert_to_previous_state: {
    label: 'Revert to Previous Status', icon: 'icon-backward', colorClass: 'color_red',
  },
};

export const FieldLabels = {
  lost_date: "Lost Date",
  found_date: "Found Date",
  claimed_date: "Claimed Date",
  pickup_date: "Return to Owner Date",
  lfin: "Item Number",
  lost_by_entity_id: 'Lost By',
  found_by_entity_id: 'Found By',
  claimed_by_entity_id: 'Claimed By',
  description: 'Description',
  current_location: 'Current Location',
  found_location: 'Found Location',
}

Object.freeze(ViewFilter);
Object.freeze(CustomerTypeList);
Object.freeze(StatusLabels);
Object.freeze(DropdownActionsByStatus);
Object.freeze(QuickActionByStatus);
Object.freeze(ActionsConfig);
Object.freeze(FieldLabels);