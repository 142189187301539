import React from 'react'
import ItemDropdown from "./ItemDropdown";
import ItemStatus from "./ItemStatus";

function ItemRow(props) {
  const {
    item, index, in_fields,

    editItem,
    openClaimItem,
    openMarkAsFoundItem,
    openReturnToOwnerItem,
    transitionAction,
  } = props

  const handleEditItem = (event) => {
    const lost_fount_item_id = event.currentTarget.id
  }
  const getFieldValue = (field) => {
    if (['lost_by', 'found_by', 'claimed_by', 'entered_by'].includes(field))
      return item[field]?.full_name
    return item[field]
  }

  return (<tr key={index}>
      <ItemDropdown
        item={item}
        editItem={editItem}
        openMarkAsFoundItem={openMarkAsFoundItem}
        openClaimItem={openClaimItem}
        openReturnToOwnerItem={openReturnToOwnerItem}
        transitionAction={transitionAction}
      />
      {in_fields.map((in_field, index) => {
        const isStatusField = in_field === "status";
        const isLinkField = ["lfin"].includes(in_field);

        return (<td
          key={index}
          style={{
            whiteSpace: "nowrap", paddingLeft: "15px", color: in_field === "status" ? "#8F8F8F" : ""
          }}
        >
          {isLinkField && (<a
            className="link"
            onClick={(e) => editItem(e.currentTarget.id)}
            id={item.id}
          >
            {item[in_field]}
          </a>)}

          {isStatusField && <ItemStatus item={item} transitionQuickAction={transitionAction}/>}

          {!isLinkField && !isStatusField && (getFieldValue(in_field) || "-")}
        </td>);
      })}
    </tr>
  )
}

export default ItemRow
